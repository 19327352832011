import { create } from 'zustand';

const useAuthStore = create((set) => ({
    auth: {},
    teamTags: [],
    indexedTeamTagsIds: {},
    teamTagsIdToEntity: {},
    unreadeableTeamTagsIdToName: {},
    teamTagsIdToName: {},
    coachLabels: {},
    teamTagIdToSessions: {},
    newEntityToGroupObj: {},
    entitiesArray: [],
    stats: {},
    idToDiscipline: {},
    actions: {
        setAuth: (auth) => set((state) => ({
            ...state,
            auth
        })),
        setValues: (values) => set((state) => ({
            ...state,
            ...values
        }))
    }
}));

export { useAuthStore };
