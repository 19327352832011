import { Spinner } from '@/icons/loader/Spinner';
import React from 'react';

const Button = React.forwardRef(({
  value = '',
  size = 'small',
  color = 'primary',
  border = 'medium',
  transition = 'ease',
  className = '',
  hoverTransiton = true,
  cursor = 'pointer',
  textSize = 'md',
  ...props
}, ref) => {
  const sizeClasses = {
    smallLong: 'h-[40px] p-[16px] font-medium leading-[24px]',
    small: 'h-[40px] font-medium leading-[24px] lg:w-fit',
    medium: 'h-[40px] pl-[20px] pr-[24px] py-[8px] font-medium leading-[24px]',
    large: 'h-[40px] font-medium leading-[24px]',
  };

  const textSizeClasses = {
    sm: "text-[14px]",
    md: "text-[16px]"
  }

  const cursorClasses = {
    pointer: 'cursor-pointer',
    default: 'cursor-default'
  }

  const transitionTypes = {
    ease: 'transition duration-200',
    leftRight: ''
  }

  const colorClasses = {
    primary: `bg-primary text-primaryforeground ${hoverTransiton ? `${transitionTypes[transition]} hover:bg-hoverprimary` : ''}`,
    secondary: `foreground border border-border bg-background ${hoverTransiton ? `${transitionTypes[transition]} hover:bg-input` : ''}`,
    destructive: `foreground border text-primaryforeground border-border bg-destructive ${hoverTransiton ? `${transitionTypes[transition]} hover:bg-destructivehover` : ''}`,
    invisibleGray: `bg-transparent border-border text-primaryforeground ${hoverTransiton ? `${transitionTypes[transition]} hover:bg-border` : ''}`
  };

  const borderRadius = {
    medium: 'rounded-[12px]',
    full: 'rounded-full'
  }

  const isSpinner = React.isValidElement(value) && value.type === Spinner;
  if (isSpinner) cursor = 'default';


  return (
    <button
      ref={ref}
      className={`flex items-center justify-center focus:outline-none focus:ring-0 focus:ring-offset-2 ${sizeClasses[size] || ""} ${colorClasses[color] || ""} ${borderRadius[border] || ""} ${textSizeClasses[textSize] || textSize} ${className} ${cursorClasses[cursor]}`}
      {...props}
    >
      {value}
    </button>
  );
});

export { Button };